import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaModal } from 'components/Styled/ContentaModal';
import { useTranslation } from 'react-i18next';

function LoginModal({ isModalOpen, onConfirm }) {
  const { t } = useTranslation();

  return (
    <ContentaModal
      title={' '}
      open={isModalOpen}
      footer={null}
      hasFooter={false}
    >
      <p dangerouslySetInnerHTML={{ __html: t('unloggedModal.text') }} />

      <div className="d-flex-row justify-center">
        <ContentaButtonPrimary onClick={() => onConfirm()}>
          {t('buttons.makeLogin')}
        </ContentaButtonPrimary>
      </div>
    </ContentaModal>
  );
}

export default LoginModal;
