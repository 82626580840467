import { Col, Divider, Form, Row, Typography } from 'antd';
import { Suspense, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FlagMenu } from 'components/FlagMenu';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';

import FirstStep from './StepsForm/FirstStep';
import SecondStep from './StepsForm/SecondStep';
import TravellerRegisterHeader from './TravellerRegisterHeader';

import { useAuth0 } from '@auth0/auth0-react';
import RedirectLoader from 'components/RedirectingLoader';
import { COUNTRIES_LIST } from 'constants/countries';
import { STATE_SCHEDULE } from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import useTravellerInputs from 'hooks/Register/useTravellerInputs';
import TravellerService from 'services/TravellerService';
import { errorHandler } from 'utils/errorHandler';
import { successHandler } from 'utils/successHandler';
import AlreadyRegistered from './AlreadyRegistered';
import * as S from './styles';
import errorLogger from 'utils/errorLogger';

export const TravellerRegister = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { whoAmI, isLoading } = useUser();
  const { user } = useAuth0();
  const { inputs, formErrors, contactsAdressInput } = useTravellerInputs({
    form,
  });

  const [formValues, setFormValues] = useState({});
  const [step, setStep] = useState(1);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [acceptTermsConditions, setAcceptTermsConditions] = useState(false);

  const hasSomeErrorField = Object.keys(formErrors).length > 0;
  const isDisabled =
    hasSomeErrorField && Object.values(formErrors).some(Boolean);

  const formOptions = {
    form,
    layout: 'vertical',
    requiredMark: false,
  };

  const redirectToBriefing = () => {
    const urlBack = sessionStorage.getItem('url_back');
    const stateSchedule = JSON.parse(sessionStorage.getItem(STATE_SCHEDULE));
    if (stateSchedule) {
      window.location.href = urlBack;
    }
  };

  async function submitData(data) {
    try {
      await TravellerService.saveUser(data);
      successHandler({ message: 'register.success' });
      setSaving(false);
      setAlreadyRegistered(true);
      redirectToBriefing();
    } catch (error) {
      errorHandler({ error });
      errorLogger({
        error,
        message: `[TravellerRegister][submitData][error] - ${JSON.stringify(
          error
        )}`,
      });
    }
  }

  async function submitForm() {
    const values = await form.getFieldsValue();
    const data = {
      ...formValues,
      ...values,
      birthDate: formValues.birthDate?.format('YYYY-MM-DD'),
      address: {
        state: values.state,
        city: values.city,
        street: values.street,
        zipcode: values.zipcode,
        complement: values.complement,
        country: {
          id: values?.country,
        },
      },
      nationality: {
        id: COUNTRIES_LIST.find((el) => el.code === formValues.nationality).id,
      },
      email: user.email,
      acceptedTerms: acceptTermsConditions,
    };

    delete data.state;
    delete data.city;
    delete data.street;
    delete data.zipcode;
    delete data.complement;
    delete data.country;

    submitData(data);
  }

  async function handleSaveForm() {
    const isFormTabValid = await form.validateFields();

    if (!isFormTabValid) return;

    if (step === 2 && isFormTabValid) {
      submitForm();
    }
  }

  const validateNextStep = async () => {
    const isFormTabValid = await form.validateFields();

    if (!isFormTabValid) return;

    if (step === 1 && isFormTabValid) {
      setFormValues(form.getFieldsValue());
      setStep(2);
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (whoAmI?.name && whoAmI?.surname) {
      setAlreadyRegistered(true);
      redirectToBriefing();
    }

    setSaving(false);
  }, [whoAmI, isLoading]);

  return (
    <Suspense fallback={<RedirectLoader />}>
      <S.Container>
        <TravellerRegisterHeader />

        <Row gutter={8}>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 14 }}
          >
            <S.Content>
              <header className="form__header">
                <h2>{t('register.completeRegister')}</h2>
                <FlagMenu />
              </header>

              <Divider />

              {alreadyRegistered ? (
                <AlreadyRegistered />
              ) : (
                <Form {...formOptions} onFinish={() => handleSaveForm()}>
                  {step === 1 && (
                    <FirstStep inputs={inputs} formErrors={formErrors} />
                  )}
                  {step === 2 && (
                    <SecondStep
                      contactsAdressInput={contactsAdressInput}
                      acceptTermsConditions={acceptTermsConditions}
                      setAcceptTermsConditions={setAcceptTermsConditions}
                    />
                  )}

                  {hasSomeErrorField &&
                    Object.entries(formErrors).map(([key, value]) => {
                      if (!value) return null;
                      return (
                        <>
                          <Typography.Text type="danger" key={key}>
                            {t(`errors.${key}`)}
                          </Typography.Text>
                          <br />
                        </>
                      );
                    })}

                  <div className="traveler-register__actions">
                    {step === 1 && (
                      <ContentaButtonPrimary
                        type="primary"
                        className="w-100"
                        onClick={() => validateNextStep()}
                        disabled={isDisabled}
                      >
                        {t('common.continue')}
                      </ContentaButtonPrimary>
                    )}
                    {step === 2 && (
                      <Form.Item>
                        <ContentaButtonPrimaryOutline
                          onClick={() => setStep(1)}
                          disabled={
                            isSaving || isDisabled || !acceptTermsConditions
                          }
                        >
                          {t('common.back')}
                        </ContentaButtonPrimaryOutline>
                        <ContentaButtonPrimary type="primary" htmlType="submit">
                          {t('common.register')}
                        </ContentaButtonPrimary>
                      </Form.Item>
                    )}
                  </div>
                </Form>
              )}
            </S.Content>
          </Col>
        </Row>
      </S.Container>
    </Suspense>
  );
};

export default TravellerRegister;
