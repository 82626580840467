import ScheduleDates from 'components/ScheduleDates';
import useAdvisorSchedulesAgenda from 'hooks/useAdvisorSchedulesAgenda';

import * as S from './styles';
import LoginModal from './LoginModal';

function MentorDetailsSchedule({
  schedules: availableSchedules,
  advisor,
  isLoading,
  setAvailableSchedules,
}) {
  const {
    auxClass,
    availableTimesWithDays,
    handleClickSchedule,
    handleSubmitSchedule,
    loadingDates,
    nextThreeDays,
    previousThreeDays,
    schedules,
    showLoginModal,
    redirectUnloggedUser,
  } = useAdvisorSchedulesAgenda({
    advisor,
    availableSchedules,
    setAvailableSchedules,
  });

  return (
    <S.CardSchedule>
      <ScheduleDates
        advisor={advisor}
        auxClass={auxClass}
        availableSchedules={schedules}
        availableTimesWithDays={availableTimesWithDays}
        handleClickSchedule={handleClickSchedule}
        handleSubmitSchedule={handleSubmitSchedule}
        isLoading={isLoading}
        loadingDates={loadingDates}
        nextThreeDays={nextThreeDays}
        previousThreeDays={previousThreeDays}
      />

      <LoginModal
        isModalOpen={showLoginModal}
        onConfirm={redirectUnloggedUser}
      />
    </S.CardSchedule>
  );
}

export default MentorDetailsSchedule;
