import * as _ from 'lodash';
import moment from 'moment';

function createAssociatedSince({ mentor = {} }) {
  const { associatedDay, associatedMonth, associatedYear, classAssociation } =
    mentor;

  const day =
    Number(associatedDay) < 10 && associatedDay.length === 1
      ? `0${associatedDay}`
      : associatedDay;
  const month =
    Number(associatedMonth) < 10 && associatedMonth.length === 1
      ? `0${associatedMonth}`
      : associatedMonth;

  if (associatedYear) {
    return `${associatedYear}-${_.padStart(month, 2, '0')}-${_.padStart(
      day,
      2,
      '0'
    )}`;
  }

  return classAssociation?.associatedSince || null; // Lida com undefined
}

function createBirthDate({ mentor = {} }) {
  const { day, month, year, birthDate } = mentor;

  const formattedDay = Number(day) < 10 && day.length === 1 ? `0${day}` : day;
  const formattedMonth =
    Number(month) < 10 && month.length === 1 ? `0${month}` : month;

  if (year) {
    return `${year}-${_.padStart(formattedMonth, 2, '0')}-${_.padStart(
      formattedDay,
      2,
      '0'
    )}`;
  }

  return birthDate || null; // Lida com undefined
}

function handleCustomSpecialty({ customSpecialty }) {
  if (typeof customSpecialty === 'string') {
    return { specialty: customSpecialty };
  }
  return customSpecialty || null;
}

function convertValueInput(value) {
  if (typeof value === 'string') {
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
    return isNaN(numericValue) ? null : numericValue;
  }

  return value || null;
}

function createMentor({ mentor = {} }) {
  return {
    birthDate: createBirthDate({ mentor }),
    currency: mentor.currency || null,
    email: mentor.email || null,
    languages: mentor.languages || [],
    mobilePhone: `${mentor.ddi || ''} ${mentor.mobilePhone || ''}`,
    name: mentor.name || '',
    nationality: mentor.nationality || null,
    summary: (mentor.tellUsAboutYou || '')
      .replace(/,+/g, '٫')
      .replace(/"/g, "'"),
    surname: mentor.surname || '',
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    profilePhrase: mentor.profilePhrase || '',
    address: {
      id: mentor?.address?.id || mentor?.addressId || null,
      city: mentor.city || '',
      complement: mentor.complement || '',
      country: { id: mentor.country || null },
      state: mentor.state || '',
      street: mentor.street || '',
      zipcode: mentor.zipcode || '',
    },
    specialties:
      mentor?.specialties?.map((specialty) => ({ code: specialty })) || [],
    ...(mentor.customSpecialty && {
      customSpecialty: { specialty: mentor.customSpecialty },
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association || '',
      registerNumber: mentor.registerNumber || '',
      guideDependsDescription: mentor.requiredLicenseDetails || null,
    },
    ...(mentor?.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany || '',
        name: mentor.nameCompany || '',
        position: mentor.role || '',
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity || '',
      country: mentor.consultancyCountry || '',
      details: (mentor.consultancyDetails || '')
        .replace(/,+/g, '٫')
        .replace(/"/g, "'"),
      name: '',
      state: mentor.consultancyState || '',
    },
    customLanguage: {
      language: mentor.otherLanguages || '',
    },
    ...(mentor?.kanaName && {
      kana: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanaName || '',
        surname: mentor.kanaSurname || '',
      },
    }),
    ...(mentor?.kanjiName && {
      kanji: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanjiName || '',
        surname: mentor.kanjiSurname || '',
      },
    }),
  };
}

function mountMobilePhone({ phone = '', ddi = '' }) {
  if (!ddi && phone) {
    return phone;
  }

  if (phone.includes(ddi)) {
    return phone;
  }

  return `${ddi} ${phone}`.trim();
}

function getCustomLanguage({ mentor = {} }) {
  const isCustomLanguageObject = typeof mentor.customLanguage === 'object';

  if (mentor.customLanguage?.language) {
    return { language: mentor.customLanguage.language };
  }
  if (mentor.otherLanguages || mentor.customLanguage) {
    return isCustomLanguageObject
      ? mentor.customLanguage
      : { language: mentor.customLanguage };
  }

  return null;
}

function updateMentor({ mentor = {} }) {
  return {
    birthDate: createBirthDate({ mentor }),
    currency: mentor.currency || null,
    email: mentor.email || null,
    languages: mentor.languages || [],
    mobilePhone: mountMobilePhone({
      phone: mentor.mobilePhone,
      ddi: mentor.ddi,
    }),
    name: mentor.name || '',
    nationality: mentor.nationality || null,
    summary: mentor.tellUsAboutYou || mentor.summary || '',
    surname: mentor.surname || '',
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    createdAt: mentor.createdAt || null,
    updatedAt: mentor.updatedAt || null,
    status: mentor.status || null,
    profilePhrase: mentor.profilePhrase || '',
    address: {
      id: mentor?.address?.id || mentor?.addressId || null,
      city: mentor.city || mentor.address.city || '',
      complement: mentor.complement || mentor.address.complement || '',
      country: mentor.country || mentor.address.country || null,
      state: mentor.state || mentor.address.state || '',
      street: mentor.street || mentor.address.street || '',
      zipcode: mentor.zipcode || mentor.address.zipcode || '',
    },
    specialties: mentor?.specialties || [],
    customSpecialty: handleCustomSpecialty({
      customSpecialty: mentor?.customSpecialty,
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association || mentor.classAssociation.name || '',
      registerNumber:
        mentor.registerNumber || mentor.classAssociation.registerNumber || '',
      guideDependsDescription:
        mentor.requiredLicenseDetails ||
        mentor.classAssociation.guideDependsDescription ||
        null,
    },
    ...(mentor?.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany || mentor.company?.document || '',
        name: mentor.association || mentor.classAssociation.name || '',
        position: mentor.role || mentor.company?.position || '',
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity || mentor.consultingRegion.city || '',
      country:
        mentor.consultancyCountry || mentor.consultingRegion.country || '',
      details:
        (mentor.consultancyDetails || '')
          .replace(/,+/g, '٫')
          .replace(/"/g, "'") ||
        mentor.consultingRegion.details ||
        '',
      name: '',
      state: mentor.consultancyState || mentor.consultingRegion.state || '',
    },
    contact: {
      id: mentor?.contact?.id || null,
      facebook: mentor?.facebook || mentor?.contact?.facebook || '',
      instagram: mentor?.instagram || mentor?.contact?.instagram || '',
      twitter: mentor?.twitter || mentor?.contact?.twitter || '',
      tikTok: mentor?.tikTok || mentor?.contact?.tikTok || '',
      site: mentor?.site || mentor?.contact?.site || '',
    },
    customLanguage: getCustomLanguage({ mentor }),
    ...(mentor?.accountNumber && {
      financial: {
        accountNumber:
          mentor.accountNumber || mentor?.financial?.accountNumber || null,
        address: mentor.agencyStreet || mentor?.financial?.address || '',
        agencyNumber:
          mentor.agencyNumber || mentor?.financial?.agencyNumber || '',
        bankName: mentor?.bankName || mentor?.financial?.bankName || '',
        bankNumber: mentor?.bankNumber || mentor?.financial?.bankNumber || '',
        brazilianAccount:
          Number(mentor.brazilianAccount) ||
          mentor?.financial?.brazilianAccount ||
          null,
        brazilianAgency:
          Number(mentor.brazilianAgency) ||
          mentor?.financial?.brazilianAgency ||
          null,
        brazilianBank:
          Number(mentor.brazilianBank) ||
          mentor?.financial?.brazilianBank ||
          null,
        brazilianDigit:
          Number(mentor.brazilianDigit) ||
          mentor?.financial?.brazilianDigit ||
          null,
        city: mentor?.agencyCity || mentor?.financial?.city || '',
        country: {
          id: mentor?.accountCountry || mentor?.financial?.country.id || '',
        },
        state: mentor?.agencyState || mentor?.financial?.state || null,
        ibanCode: mentor?.ibanCode || mentor?.financial?.ibanCode || '',
        swiftCode: mentor?.swiftCode || mentor?.financial?.swiftCode || '',
        zipCode: mentor?.agencyZipcode || mentor?.financial?.zipCode || '',
      },
    }),
    ...(mentor?.kanaName && {
      kana: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanaName || '',
        surname: mentor.kanaSurname || '',
      },
    }),
    ...(mentor?.kanjiName && {
      kanji: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanjiName || '',
        surname: mentor.kanjiSurname || '',
      },
    }),
    imageURL: mentor?.imageURL || '',
  };
}

export default { createMentor, updateMentor };
