import { createGlobalStyle } from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';
import '@smastrom/react-rating/style.css';

const GlobalStyle = createGlobalStyle`
  /* * {
    outline: 1px dotted;
  } */

  * {
  font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
    overflow-x: hidden;
  }

  .ant-drawer-content {
    padding: 16px;
  }

  .ant-layout {
    background: ${({ theme }) => theme.colors.neutralWhite[10]};
  }

  .text-center {
    text-align: center;
  }

  .d-flex-row {
    display: flex;
    flex-flow: row nowrap;
  }

  .d-flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .align-center {
    align-items: center;
  }

  .m-0 {
    margin: 0;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .pb-24 {
    padding-bottom: 24px;
  }

  .ml-8 {
    margin-left: 8px;
  }

  .pX-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .g-8 {
    gap: 8px;
  }

  .w-100 {
    width: 100%!important;
  }

  .h-auto {
    height: auto!important;
  }

  .borderless {
    border: none;
  }

  .rc-virtual-list-holder {
    overflow-y: scroll !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    display: none;
  }

  .d-flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .flex-column {
    flex-flow: column nowrap;
  }

  .ant-select-item-option {
    .ant-select-item-option-content {
      color: #757575;
      font-family: ${(props) => props.theme.fonts.texts};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .ant-modal-title {
    color: ${(props) => props.theme.colors.neutralBlack[20]};
    display: flex;
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;

  }

  .text-paragraph {
    color: ${(props) => props.theme.colors.neutralBlack[40]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
  }

  .ant-input,
  .is-number-input,
  .ant-currency {
    -moz-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-family: ${(props) => props.theme.fonts.number}!important;
    font-feature-settings: "lnum";
    input, .ant-input {
      -moz-font-feature-settings: "lnum";
      -webkit-font-feature-settings: "lnum";
      font-family: ${(props) => props.theme.fonts.number}!important;
      font-feature-settings: "lnum";
    }
  }

  .contenta-date-picker {
    border: 1px solid black;
    padding: 16px;
    width: 100%;
  }

  .ant-card-bordered {
    border-radius: 8px;
    border: 1px solid #f0f0f0;
  }

  .mentor-details__badge {
    transform-origin: 0!important;
    border-radius: 7px;
    margin-bottom: 8px;

    sup {
      background: ${({ theme }) => theme.colors.linearGradient.bluePink};
      font-weight: 700;
    }
  }

  .card--shadow {
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  textarea.ant-input {
    background: ${({ theme }) => theme.colors.neutralWhite[10]};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutralBlack[10]};
    height: 100%;
    min-height: 48px;
    padding: 12px;

    &:focus,
    &:active,
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.neutralBlack[10]};
    }
  }

  .contenta-input {
    border-radius: 8px !important;
    border: 1px solid #4a4647 !important;
    color: ${({ theme }) => theme.colors.neutralBlack[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'lnum';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 100%;
    min-height: 48px;
  }

  .ant-picker {
    background: ${({ theme }) => theme.colors.neutralWhite[10]};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutralBlack[10]};
    height: 100%;
    min-height: 48px;
  }

  .pointer {
    cursor: pointer;
  }

  p {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
  }

  .confirmation-modal {
    .ant-modal-footer {
      padding: 0 32px 32px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.pink[30]};
  }


  .schedule-modal {
    .ant-tabs-nav-list {
      align-items: center;
      display: flex;
      flex-flow: column wrap;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-flow: row wrap;
      }
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: none;
      }
    }
  }

  .modal-traveller-agenda {
    .ant-modal-content{
      padding: 0;
    }

    .ant-modal-header {
      border-radius: 16px 16px 0 0!important;
      padding: 0!important;
      margin: 0!important;
    }

    .ant-modal-body {
      padding: 0!important;
    }

    .ant-modal-content {
      background: ${({ theme }) => theme.colors.neutralBlack[30]};
    }
  }

  .anticon {
    display: initial!important;
  }
  .ant-picker-input {
    .anticon .anticon-calendar {
      color: #D82EA1;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      background: white;
      border-radius: 50%;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
      font-size: 12px;
      height: 55px;
      width: 55px;
      z-index: 9999;
      &:after {
        align-items: center;
        display: flex;
        font-size: 18px;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    }
  }

  .ant-empty-description {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    margin-top: 16px;
  }

  .ant-select-item-option-content {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'lnum';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 20px;
  }

  .contenta-text {
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'lnum';
    font-style: normal;
    letter-spacing: 0.02em;
    line-height: 20px;
  }

  .modal__tab-no-clickable{
    .ant-tabs-tab,
    .ant-tabs-tab-btn {
      cursor: default;
    }
  }

  .contenta-scroll {
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export default GlobalStyle;
