import { Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { ContentaCheckbox } from 'components/Styled/ContentaCheckbox';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useTranslation } from 'react-i18next';

import termsPT from 'assets/docs/termos-viajante.pdf';
import termsPDF from 'assets/docs/terms-traveller.pdf';
import { useLanguage } from 'contexts/languageContext';

function SecondStep({
  contactsAdressInput,
  acceptTermsConditions,
  setAcceptTermsConditions,
}) {
  const { currentLanguage } = useLanguage();
  const pdf = currentLanguage === 'pt-BR' ? termsPT : termsPDF;
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={8}>
        {contactsAdressInput.map((input) => (
          <Col key={input.name} xs={24} md={12}>
            <ContentaInputGroup>
              <Form.Item
                hasFeedback={input.hasFeedback}
                label={input.label}
                name={input.name}
                placeholder={input?.placeholder}
                rules={[
                  { required: input.isRequired, message: input?.message },
                ]}
              >
                {input.input || (
                  <Input
                    onBlur={input?.onBlur}
                    className={input?.className}
                    disabled={input?.disabled}
                    placeholder={input?.placeholder || input.label}
                  />
                )}
              </Form.Item>
            </ContentaInputGroup>
          </Col>
        ))}
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <ContentaInputGroup>
            <Checkbox.Group>
              <Space direction="vertical">
                <ContentaCheckbox
                  value={acceptTermsConditions}
                  onChange={() => setAcceptTermsConditions(true)}
                >
                  {t('terms.title')}
                  <a href={pdf} target="_blank" rel="noreferrer">
                    {t('terms.terms')}
                  </a>
                </ContentaCheckbox>
              </Space>
            </Checkbox.Group>
          </ContentaInputGroup>
        </Col>
      </Row>
    </>
  );
}

export default SecondStep;
