/* eslint-disable consistent-return */
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, Tag } from 'antd';
import Avatar from 'components/Avatar';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';
import { APP_LANGUAGES } from 'constants/app-languages';
import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import { isUserTraveller } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';
import MenuMentorIcon from '../../Icons/MenuMentorIcon';
import * as S from './styles';
import useNavbar from './useNavbar';
import { useEffect } from 'react';

const isHome = window.location.pathname === '/';

export function Navbar({ showDrawer }) {
  const { user } = useAuth0();
  const { userRole, whoAmI, logout } = useUser();
  const { currentLanguage, handleChangeLanguage } = useLanguage();
  const {
    checkURLRedirect,
    getLogo,
    getMenuIconType,
    handleBeTravelAdvisor,
    handleFinishRegister,
    handleLogin,
  } = useNavbar();
  const isRegistered = checkIsRegistered({ whoAmI });

  const getButtonColorClassName = () => {
    if (user) {
      return '--white';
    }
    return '';
  };

  const getHeaderColor = () => {
    const isPublicRoute = isUnloggedRoute(window.location.pathname);
    if ((isPublicRoute && !userRole) || (isHome && !user)) {
      return '--gray';
    }

    return '';
  };

  const renderNavbarButtons = () => {
    if (!user) {
      return (
        <>
          <ContentaButtonPrimaryOutline
            onClick={handleBeTravelAdvisor}
            className={getButtonColorClassName()}
          >
            {t('buttons.beAdvisor')}
          </ContentaButtonPrimaryOutline>
          <ContentaButtonPrimary
            onClick={() => handleLogin()}
            className={getButtonColorClassName()}
          >
            {t('buttons.enter')}
          </ContentaButtonPrimary>
        </>
      );
    }
  };

  const handleSelectLanguage = (selectLanguage) => {
    const languageObject = APP_LANGUAGES.find(
      (language) => language.key === selectLanguage
    );
    handleChangeLanguage(languageObject?.key);
  };

  const items = [
    {
      label: t('common.languages.en-US'),
      key: 'en-US',
    },
    {
      label: t('common.languages.pt-BR'),
      key: 'pt-BR',
    },
  ];

  const renderFlag = () => {
    if (currentLanguage === 'pt-BR' || currentLanguage === 'PT-BR') {
      return (
        <img
          src="/BRASIL.webp"
          alt="BRA"
          className="header__content-language-flag"
        />
      );
    }
    return (
      <img
        src="/EUA.webp"
        alt="USA"
        className="header__content-language-flag"
      />
    );
  };

  const onClickChangeLanguage = (i) => {
    handleSelectLanguage(i.key);
  };

  useEffect(() => {
    if (user && !isRegistered) {
      console.log('logout from navbar');
      // logout();
    }
  }),
    [user, isRegistered];

  return (
    <S.Header
      isMentor={!isUserTraveller(userRole)}
      isUnlogged={userRole === '' || userRole === undefined}
      isNotRegistered={!isRegistered}
    >
      <div className="header__content">
        <div className="header__content-leftside">
          {user && (
            <button
              className="trigger header__button"
              onClick={showDrawer}
              type="button"
            >
              <MenuMentorIcon type={getMenuIconType()} />
            </button>
          )}
          <Link
            to={checkURLRedirect()}
            className="header__content-logo-container"
          >
            <Avatar className="header__content-logo" src={getLogo()} />
          </Link>
          <span className={`header__content-logo-phrase ${getHeaderColor()}`}>
            {t('common.contentaPhraseLogo')}
          </span>
        </div>

        <div className="header__content-rightside">
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                onClickChangeLanguage(e);
              },
            }}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>{renderFlag()}</a>
          </Dropdown>

          {user && isRegistered ? (
            <S.Username isGray={isHome && !user}>
              {t('common.hello')},{' '}
              <strong>{whoAmI?.name?.split(' ')[0]}</strong>
            </S.Username>
          ) : (
            renderNavbarButtons()
          )}
          {window.innerWidth > 768 && (
            <Tag
              color="transparent"
              className={`header__tag-version ${
                userRole === '' || userRole === undefined ? '--dark' : ''
              }`}
            >
              {t('common.betaVersion')}
            </Tag>
          )}
        </div>
      </div>
    </S.Header>
  );
}
