import { Col } from 'antd';
import AdvisorCard from 'components/AdvisorCard';
import ScrollToTop from 'components/ScrollToTop';
import useBookingAdvisor from 'hooks/useBookingAdvisor';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import MentorDetailsHeader from '../MentorDetailsHeader';
import MentorDetailsSchedule from '../MentorDetailsSchedule';
import MentorDetailsTabs from '../MentorDetailsTabs';

import * as S from '../styles';
import handleLastnameByRole from 'utils/handleLastnameByRole';
import { useUser } from 'contexts/userContext';
import { useMentor } from '../mentorContext';

function MentorContainer() {
  const { id } = useParams();
  const { state } = useLocation();
  const { getAdvisorSchedules } = useBookingAdvisor();
  const { userRole } = useUser();
  const { mentor, isLoading: isLoadingAdvisor } = useMentor();
  const [advisorInfo, setAdvisorInfo] = useState({});
  const [availableSchedules, setAvailableSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const advisorState = state?.advisor;

  const getAvailableSchedules = async () => {
    setIsLoading(true);
    const dates = await getAdvisorSchedules(id);
    setAvailableSchedules(dates);
    setIsLoading(false);
  };

  useEffect(() => {
    setAdvisorInfo((advisor) => ({
      ...advisor,
      ...advisorState,
      ...mentor,
    }));
  }, [mentor, advisorState]);

  useEffect(() => {
    getAvailableSchedules();
  }, [id]);

  return (
    <>
      <ScrollToTop />
      <MentorDetailsHeader />

      <S.MentorDetailsContainer gutter={36}>
        <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
          <AdvisorCard
            advisor={{
              name: `${
                mentor?.name || advisorState?.name
              } ${handleLastnameByRole({
                role: userRole,
                lastname: mentor?.surname || advisorState?.surname,
              })}`,
              title: mentor?.title || advisorState?.title,
              image: mentor?.imageUrl || advisorState?.imageUrl,
              video: mentor?.videoUrl || advisorState?.videoUrl,
              profilePhrase:
                mentor?.profilePhrase || advisorState?.profilePhrase,
            }}
            isLoadingAdvisor={isLoadingAdvisor}
          />
          <MentorDetailsTabs mentor={mentor} />
        </Col>

        <Col
          xs={24}
          style={{ marginLeft: '0' }}
          md={{
            span: 24,
            offset: 0,
          }}
          lg={{
            span: 10,
            offset: 2,
          }}
          xl={{
            span: 9,
            offset: 3,
          }}
          xxl={{
            span: 8,
            offset: 4,
          }}
        >
          <MentorDetailsSchedule
            schedules={availableSchedules}
            setAvailableSchedules={setAvailableSchedules}
            advisor={advisorInfo || mentor}
            isLoading={isLoading}
          />
        </Col>
      </S.MentorDetailsContainer>
    </>
  );
}

export default MentorContainer;
